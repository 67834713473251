import React from 'react'
import Seo from '../components/SEO'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import PageHero from '../components/shared/page-hero'
import PageBody, {
  PageContainer,
  ContentImage,
} from '../components/shared/page-body'

const TailoredShirtsPage = ({ data }) => (
  <Layout>
    <Seo
      title="Tailored Shirts — Made to Measure"
      description="Design every detail with made to measure shirts. Choose from up to 17 different collar shapes, classic, cutaway, extreme cutaway, long points, short points, rounded collars and even tab collars and pin collars."
      pathname="/tailored-shirts/"
    />
    <PageHero image={data.hero.childImageSharp} title="Tailored Shirts" />
    <PageBody>
      <PageContainer maxWidth={840} padded>
        <h1 className="heading--h1">
          Design every detail with made to measure shirts
        </h1>
        <p>
          The emerging demand for 'individuality' in shirts is on the increase,
          not just in fabrics but in style and fit. The shirt market and the
          availability of shirts is huge – but this is still not enough. The
          demand for something different is continual, but is not always
          available – until now.
        </p>
        <ContentImage {...data.lightBlueShirt.childImageSharp} alt="" />
        <p>
          Choose from a large range of top quality fabrics from plains, stripes
          and checks through to fancy designs and floral prints.
        </p>
        <ContentImage {...data.bsr.childImageSharp} alt="" />
        <ContentImage {...data.purpleCuff.childImageSharp} alt="" />
        <ContentImage
          {...data.pinkCollarCuffs.childImageSharp}
          alt="Custom Tailored Shirt with Pink Collar and Cuffs"
        />
        <p>
          Choose from up to 17 different collar shapes, classic, cutaway,
          extreme cutaway, long points, short points, rounded collars and even
          tab collars and pin collars. Add to that your choice of monograms in
          various colours, positions and fonts, together with different inner
          collar and cuff fabrics, you have the 'Ultimate' shirt – not seen
          anywhere – and it is made to fit either slim, regular or generous.
        </p>
        <p>Prices from £95 per shirt – great value, great shirt.</p>
      </PageContainer>
    </PageBody>
  </Layout>
)

export default TailoredShirtsPage

export const query = graphql`
  {
    hero: file(relativePath: { eq: "img/purple-shirt-collar-hero.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bsr: file(relativePath: { eq: "img/tailored-shirts-hero.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    purpleCuff: file(relativePath: { eq: "img/purple-shirt-ab-cuff.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pinkCollarCuffs: file(
      relativePath: { eq: "img/tailored-shirts-feature.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 600, quality: 70) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lightBlueShirt: file(
      relativePath: { eq: "img/light-blue-shirt-waistcoat.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 600, quality: 70) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
